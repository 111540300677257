<template>
  <div class="modal fade " :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">{{ modalTitle }}</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetData()">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label>Service Type *</label>
                  <VueMultiselect v-model="selectedServiceType" :options="serviceTypeOptions" :close-on-select="true"
                                  placeholder="Select service" label="name" track-by="value" :show-labels="false"
                                  :allow-empty="false"/>
                  <div class="text-danger" v-if="errors.service_id">{{ errors.service_id }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <div class="">
                    <label>Service tips</label>
                    <textarea v-model="postServiceTipsData.tips" class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="submit" class="btn btn-primary"
                  @click="serviceTipId !== null ? updateSingleServiceTipInfo() : createSingleServiceTipInfo()">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">{{
                serviceTipId !== null ? "Update Changes" : "Save Changes"
              }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// core packages
import {mapGetters, mapActions} from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';

export default {
  name: "ServiceTipUpdateOrCreateModal",
  mixins: [ShowToastMessage, Loader],
  props: {
    modalName: {
      type: String,
      required: true,
      default: "UserAdditionalInfoCreateModal"
    },
    serviceTipId: {
      type: [Number, String],
      default: null,
    },
    serviceTipDetails: {
      type: [Object],
      required: false,
      default: () => ({}),
    }
  },
  components: {
    VueMultiselect,
  },
  data() {
    return {
      postServiceTipsData: {
        service_id: '',
        tips: ''
      },

      statusOptions: [
        {
          value: 0,
          name: 'Active',
        },
        {
          value: 1,
          name: 'Inactive',
        }
      ],

      selectedOptions: {
        value: '',
        name: 'None',
      },

      selectedServiceType: {
        value: '',
        name: 'None',
      },

      errors: {
        service_id: '',
        tips: '',

      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },

      getServiceParams: {
        status: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      services: 'appService/services'
    }),
    serviceTypeOptions() {
      return this.services.filter(({status}) => status === 'Active').map((service) => {
        return {
          name: service.name,
          value: service.id,
        }
      });
    },

    modalTitle() {
      return this.serviceTipId === null ? "Create Service Tips" : "Update Service Tips";
    },

  },
  watch: {
    serviceTipDetails(serviceTip) {
      this.postServiceTipsData.tips = serviceTip?.tips;
      this.selectedServiceType = this.serviceTypeOptions.find(({value}) => value === serviceTip?.service?.id);
    },
    selectedServiceType(selectedType) {
      this.postServiceTipsData.service_id = selectedType?.value;
    },
  },
  methods: {
    ...mapActions({
      getServices: 'appService/getServices',

      postServiceTip: 'appServiceTips/postServiceTipOnList',
      putServiceTip: 'appServiceTips/putServiceTipOnList'
    }),

    async closeModal() {
      document.querySelector(`[data-target="#${this.modalName}"]`).click(); // modal close
    },

    async emitServiceTipsData(serviceTipsData) {
      this.$emit('serviceTipsData', serviceTipsData);
    },

    async updateSingleServiceTipInfo() {
      this.loader(true);
      let dataObj = {
        id: this.serviceTipId,
        data: {
          service_id: this.postServiceTipsData.service_id,
          tips: this.postServiceTipsData.tips,
        }
      }
      await this.putServiceTip(dataObj).then(async (response) => {
        if (response.status === 200) {

          let serviceTipInfo = this.$store.getters['appServiceTips/serviceTip'];
          console.log(serviceTipInfo);

          let successToastObj = {message: 'service tip updated successfully.', type: 'success'};

          await this.closeModal();
          this.showToastMessage(successToastObj);
          //await this.emitServiceTipsData(serviceTipInfo);
        }
        this.errors.service_id = response?.errors?.service_id?.[0] ?? '';
        this.errors.tips = response?.errors?.tips?.[0] ?? '';

        this.showToastMessage(response);
      });
      this.loader(false);
    },

    async createSingleServiceTipInfo() {
      this.loader(true);
      await this.postServiceTip(this.postServiceTipsData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let serviceTipInfo = this.$store.getters['appServiceTips/serviceTip'];
          console.log(serviceTipInfo)
          let successToastObj = {message: 'service tips create successfully.', type: 'success'};

          await this.closeModal();
          this.showToastMessage(successToastObj);
          //await this.emitServiceTipsData(serviceTipInfo);
          this.resetData();
        }

        this.errors.service_id = response?.errors?.service_id?.[0] ?? '';
        this.errors.tips = response?.errors?.tips?.[0] ?? '';


        this.showToastMessage(response);
      });
      this.loader(false);
    },

    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }

  },
  async mounted() {
    await this.getServices(this.getServiceParams);
  }
}
</script>

<style scoped>

</style>