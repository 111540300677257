<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Service Tips/List</h4>
          <div>
            <button :disabled="!can('service-tip-create')"
                    :title="can('service-create') ? 'Create' : 'Create Forbidden'"
                    data-toggle="modal" data-target="#serviceTipsCreateModal"
                    class="btn">
              <span class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </button>
          </div>
        </div>
        <div class="users-list-filter px-1">
          <div class="row border rounded py-2 mb-2 align-items-center">
            <div class="col-12 col-sm-12 col-md-2">
              <label>Show Per Page</label>
              <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                              label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <label>Service</label>
              <VueMultiselect v-model="selectedService" :options="serviceList" :close-on-select="true"
                              label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
              <label>Search</label>
              <input v-model="getServiceTipsParams.tips"
                     class="form-control search-product-input-element" type="text"
                     placeholder="Search by tips"/>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <button @click="applyServiceTipsFilter(null)"
                      class="btn btn-primary btn-block glow users-list-clear mb-0">
                Filter
              </button>
            </div>
          </div>
        </div>
        <div class="users-list-table" ref="serviceCategoriesListRef">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '5%' }">SL</th>
                    <th class="position-relative" :style="{ width: '30%' }">Service</th>
                    <th class="position-relative" :style="{ width: '60%' }">TIPS</th>
                    <th class="position-relative" :style="{ width: '5%' }">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(serviceTip, index) in serviceTips" :key="index">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ serviceTip.service.name }}
                    </td>
                    <td>
                      {{ serviceTip.tips }}
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <button :disabled="!can('service-tip-view')"
                                :title="can('service-tip-view') ? 'View' : 'View Forbidden'"
                                @click="getSingleServiceTips(serviceTip.id)"
                             class="btn text-decoration-none view-edit-delete-icon">
                          <div class="" :style="{ height: '18px' }">
                            <i class="bx bx-edit"></i>
                          </div>
                        </button>
                        <div class="dropdown py-0 px-0 view-edit-delte-icon">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">
                            <button :disabled="!can('service-tip-delete')"
                                    @click="modelIdForDelete = serviceTip.id" data-toggle="modal"
                                    :title="can('service-tip-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-target="#deleteServiceTipsModal" class="dropdown-item">Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyServiceTipsFilter"/>
              </div>
            </div>
          </div>
        </div>

        <div class="" data-toggle="modal" data-target="#serviceTipsUpdateModal"></div>
        <div class="" data-toggle="modal" data-target="#serviceTipsCreateModal"></div>

        <ServiceTipsUpdateModal :service-tip-id="selectedServiceTipsDetails.id" modal-name="serviceTipsUpdateModal"
                                :serviceTipDetails="selectedServiceTipsDetails"/>
        <ServiceTipsCreateModal modal-name="serviceTipsCreateModal"/>

        <DeleteAlertModal modal-name="deleteServiceTipsModal" model-name="serviceTips" :model-id="modelIdForDelete"
                          @confirmModelDeletion="onConfirmModelDeletion"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ServiceTipsCreateModal from "@/views/backEnd/serviceTips/includes/ServiceTipUpdateOrCreateModal";
import ServiceTipsUpdateModal from "@/views/backEnd/serviceTips/includes/ServiceTipUpdateOrCreateModal";
import DeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import {mapActions, mapGetters} from "vuex";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "ServiceTipList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    DeleteAlertModal,
    ListPagination,
    ServiceTipsUpdateModal,
    ServiceTipsCreateModal,
  },
  data() {
    return {
      modelIdForDelete: '',
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      getSettingsParams: {
        type: ["default"],
        key: ["default_pagination"],
      },
      getServicesParams: {
        status: 1,
        order_by_name: 'ASC',
      },
      selectedService: {
        value: '',
        name: 'Any',
      },
      getServiceTipsParams: {
        tips: '',
        service_id: '',
        with_relation: ['service'],
        paginate: 1,
        pagination: "",
        order_by_id: "DESC",
      },
      selectedServiceTipsDetails: {
        id: ''
      },
    };
  },
  computed: {
    ...mapGetters({
      services: "appService/services",
      serviceTips: "appServiceTips/serviceTips",
      paginateLinks: "appServiceTips/paginateLinks",
    }),

    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },


    serviceList() {
      let services =  this.services ? this.services.map(service => {
        return {
          value: service.id,
          name: service.name,
        }
      }) : [];

      return [
        {
          value: '',
          name: 'Any',
        },
        ...services,
      ]
    },
  },
  watch: {
    selectedPagination(selectedService) {
      this.getServiceTipsParams.pagination = selectedService.value;
    },
    selectedService(selectedService) {
      this.getServiceTipsParams.service_id = selectedService.value;
    },

  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
      getServices: "appService/getServices",
      getServiceTips: "appServiceTips/getServiceTips",
      deleteServiceTips: "appServiceTips/deleteServiceTipOnList",
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getServiceList() {
      await this.getServices(this.getServicesParams);
    },

    async getServiceTipsList() {
      await this.getServiceTips(this.getServiceTipsParams).then(async (response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          }
      );
    },

    async applyServiceTipsFilter(pageNumber) {
      this.getServiceTipsParams.page = pageNumber;
      await this.loader(true);
      await this.getServiceTipsList();
      await this.loader(false);
    },

    async getSingleServiceTips(id) {
      this.selectedServiceTipsDetails = {};
      this.selectedServiceTipsDetails = await this.serviceTips.find((item) => item.id === id);
      document.querySelector('[data-target="#serviceTipsUpdateModal"]').click();
    },

    async deleteSingleServiceTipsOnList(id) {
      this.deleteServiceTips(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'Service Tip deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleServiceTipsOnList(confirmModelDeletion.modelId);
    },

  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getServiceList();
    await this.getServiceTipsList();
    await this.loader(false);
  },
}
</script>

<style scoped>

</style>